export default {
  sections: [
    {
      head: 'Жилье',
      component: 'house',
      backgroundSvg: 'home',
    },
    {
      head: 'Личный транспорт',
      component: 'transport',
      backgroundSvg: 'zapravka',
    },
    {
      head: 'Логистика',
      component: 'logistics',
      backgroundSvg: 'cityfinale',
    },
    {
      head: 'Пища',
      component: 'food',
      backgroundSvg: 'food',
    },
    {
      head: 'Путешествия',
      component: 'travel',
      background: 'travel-svg',
    },
  ],
  houseModel: null,
  logisticsModel: null,
  foodModel: null,
  travelModel: null,
  transportModel: null,
  total: null,
  recommendations: null,
  group: 0,
  models: [
    { model: 'houseModel', key: 'house' },
    { model: 'foodModel', key: 'food' },
    { model: 'travelModel', key: 'travel' },
    { model: 'logisticsModel', key: 'public_transport' },
    { model: 'transportModel', key: 'car' },
  ],
};
