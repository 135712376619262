<template>
  <v-app id="inspire">
    <v-app-bar class="main-bar" app color="green" dark height="50" tile  dense>
      <div class="pages" v-if="$vuetify.breakpoint.width > 800">
        <v-tabs backgroundColor="green" v-model="group">
          <v-tab v-for="(page, i) in pages" :key="i" @click="push(page.push)">
            <v-btn
              v-text="page.text"
              small
              plain
            />
          </v-tab>
        </v-tabs>
      </div>
      <div class="mobile-bar" v-else>
        <v-app-bar-nav-icon  @click="drawer = true"/>
        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          height="100vh"
          right
          color="#289d2dd9"
        >
          <v-list
            nav
            dense
          >
            <v-list-item-group
              v-model="group"
              active-class="text--accent-4"
            >
              <v-list-item v-for="(page, i) in pages" :key="i">
                <v-list-item-title v-text="page.text" @click="push(page.push)" />
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </v-app-bar>
    <v-main>
      <keep-alive>
        <router-view class="router" />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    drawer: false,
    pages: [
      {
        text: 'Главная',
        push: '/',
      },
      {
        text: 'Калькулятор',
        push: '/calc',
      },
      {
        text: 'Статьи',
        push: '/articles',
      },
    ],
  }),
  computed: {
    group: {
      get() {
        return this.$store.state.group;
      },
      set(val) {
        this.$store.commit('SET_GROUP', val);
      },
    },
  },
  methods: {
    push(path) {
      this.$router.push(path).catch(() => {

      });
    },
  },
};
</script>

<style lang="sass">
  .v-main
    background-size: cover
    background-color: #ffffff
    height: 100vh
  .v-application
    line-height: 1.35
  \:root
    font-size: 22px
  @font-face
    font-family: Montserrat
    src: url('./assets/fonts/Montserrat-Regular.ttf')
  *
    transition: opacity 1s ease-in-out
    font-family: Montserrat !important
  span.head
    font-style: normal
    font-weight: normal
    line-height: 1em
    text-align: center
    letter-spacing: 0.15em
    color: #62AF47
    margin-bottom: 5px
    font-size: 34px
  span.description
    font-style: normal
    font-weight: normal
    line-height: 1em
    text-align: center
    color: #FFFFFF
    font-size: 22px
  .set-border
    background: rgba(0, 0, 0, 0.5)
    border: 3px solid #6FCF4D
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    padding: 5px 10px
  svg
    transition: opacity 1s ease-in-out
  .disable
    pointer-events: none !important
    transition: opacity 1s ease-in-out
    opacity: 0 !important
  .router
    height: 100%
  .wrap
    max-width: 1024px
    display: flex
    padding-top: 30px
    margin: 0 auto
  .text
    font-style: normal
    font-weight: 600
    font-size: 24px
    line-height: 1.5
    color: #4caf50
  .main-bar
    > div
      justify-content: center
    .pages
      display: flex
    .mobile-bar
      margin-left: auto
  .theme--dark
    .v-icon
      color: rgb(111, 207, 77)
  .v-application
    .primary--text
      color: rgb(111, 207, 77) !important
      caret-color: rgb(111, 207, 77) !important
  @media only screen and (max-width: 640px)
    \:root
      font-size: 18px
</style>
