/**
 * @param instance {AxiosInstance}
 * @returns {{getAll(*): *}}
 */
export default (instance) => ({
  getAll(params) {
    return instance.get('/', {
      params,
    });
  },
  get(id) {
    return instance.get(`/${id}`);
  },
});
