export default {
  SET_ARTICLES(state, articles) {
    state.articles = articles;
  },
  SET_PAGES(state, pageInfo) {
    state.pageInfo = pageInfo;
  },
  SET_DETAIL_ARTICLE(state, detailArticle) {
    state.detailArticle = detailArticle;
  },
  UPDATE_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },
};
