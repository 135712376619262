export default {
  articles: [],
  pageInfo: {
    total: 0,
    pages: 1,
    limit: 10,
    offset: 0,
  },
  detailArticle: {},
  filters: {
    pub_date__lte: new Date(),
  },
};
