import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main'),
  },
  {
    path: '/calc',
    name: 'Calc',
    component: () => import('../views/Calc'),
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('../views/Result'),
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import('../views/Articles'),
  },
  {
    path: '/articles/:id',
    name: 'Article',
    component: () => import('../views/Article'),
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/Personal'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
