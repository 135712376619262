import { articles } from '@/api';

export default {
  async getAll({ state, commit }, { offset = 0 } = {}) {
    try {
      const { data } = await articles.getAll({ offset, order_by: '-pub_date' });
      commit('SET_ARTICLES', data.items);
      commit('SET_PAGES', {
        ...state.pageInfo,
        pages: data.page_info.pages,
        total: data.page_info.total,
      });
    } catch (e) {
      console.error(e);
    }
  },
  async get({ state, commit }, id) {
    try {
      const { data } = await articles.get(id);
      commit('SET_DETAIL_ARTICLE', data);
    } catch (e) {
      console.error(e);
    }
  },
};
