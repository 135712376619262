import axios from 'axios';
import modules from '@/api/modules';

export const baseURL = /localhost/.test(window.origin) ? 'http://localhost:8000/' : `${window.origin}/`;
const getInstance = (path) => axios.create({
  baseURL: `${baseURL}api/v1${path}`,
  withCredentials: false,
});
/**
 *
 */
export const emissions = modules.emissions(getInstance('/emissions'));
export const articles = modules.articles(getInstance('/articles'));
