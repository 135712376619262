import { emissions } from '@/api';
import router from '@/router';

export default {
  async calc({ state, commit }) {
    const valuesObj = {};
    state.models.forEach(({ model, key }) => { valuesObj[key] = state[model].getValues(); });
    try {
      const { data } = await emissions.calc(valuesObj);
      commit('SET_TOTAL', data.result);
      commit('SET_RECOMMENDATIONS', data.recommendations);
      await router.push('/result');
    } catch (e) {
      console.error(e);
    }
  },
};
