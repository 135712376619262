import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  breakpoint: {
    mobileBreakpoint: 'sm', // This is equivalent to a value of 960
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#6fcf4d',
      },
    },
  },
});
