<template>
  <component
     :is="require(`@assets/icons/${this.name}.svg`)"
     class="BaseIcon"
     v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'BaseIcon',

  // Transparent wrapper component
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
 .BaseIcon {
   height: 50px
 }
 </style>
